<template>
  <div id="RegisterForm">
<!-- bootstrap-vue card in the center -->
    <div class="card">
      <div class="card-header">
        <h3>Register</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="email" class="form-control" id="email" placeholder="Enter email"
                       v-model="state.email">
            <span v-if="v$.email.$error">
              {{v$.email.$errors[0].$message}}
            </span>
          </div>
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" class="form-control" id="username" placeholder="Username"
                       v-model="state.username">
            <span v-if="v$.username.$error">
              {{v$.username.$errors[0].$message}}
            </span>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" id="name" placeholder="Enter Password"
                       v-model="state.password">
            <span v-if="v$.password.$error">
                  {{v$.password.$errors[0].$message}}
                  </span>
          </div>
          <div class="form-group">
            <label for="password_confirmation">Password Confirmation</label>
            <input type="password" class="form-control"
                   id="password_confirmation" v-model="state.password_confirmation">
            <span v-if="v$.password_confirmation.$error">
                  {{v$.password_confirmation.$errors[0].$message}}
                  </span>
          </div>
          <button type="submit" class="btn btn-primary" v-on:click="register">Register</button>
          <button type="button" class="btn btn-secondary"
          v-on:click="login">Already Have An Account?</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required, email, minLength, sameAs,
} from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { computed, reactive } from 'vue';
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();
    const state = reactive({
      email: '',
      username: '',
      password: '',
      password_confirmation: '',
    });

    const rules = computed(() => ({
      email: [required, email],
      password: [required, minLength(5)],
      username: [required, minLength(5)],
      password_confirmation: [required, sameAs(state.password)],
    }));

    const v$ = useVuelidate(rules, state);

    return {
      state,
      v$,
      toast,
    };
  },
  name: 'RegisterFrom',
  methods: {
    async register() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.axios.post(`https://${this.$store.state.ip}/register`, {
          email: this.state.email,
          username: this.state.username,
          password: this.state.password,
        })
          .then((response) => {
            this.successToast(response.data.success);
            this.login();
          })
          .catch((error) => {
            this.toast.error(error.response.data.error);
          });
      }
    },
    login() {
      this.$router.push('/login');
    },
    successToast(message) {
      if (this.successToastBoolean === false) {
        this.successToastBoolean = true;
        this.toast.success(message, {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
        this.$router.push('/');
      }
    },
    errorToast(message) {
      if (this.errorToastBoolean === false) {
        this.errorToastBoolean = true;
        this.toast.error(message, {
          autoHideDelay: 5000,
          closeOnClick: true,
        });
      }
    },
  },
  data() {
    return {
      errorToastBoolean: false,
      successToastBoolean: false,
    };
  },
};
</script>

<style scoped>

#RegisterForm {
  font-family: 'Uni Sans Heavy',serif;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  min-height: 100vh;
}
.card{
  width: 25rem;
}
.btn-primary {
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
}
.btn-secondary {
  margin-top: 10px;
  width: 100%;
  font-size: 20px;
  font-weight: lighter;
}
.card-header {
  text-align: center;
}
.form-group {
  margin-bottom: 20px;
}
</style>
