<template>
  <NavBar/>
  <RegisterForm/>
</template>

<script>
import RegisterForm from '../components/Register/RegisterForm.vue';
import NavBar from '@/components/NavBar.vue';

export default {
  name: 'Register',
  components: {
    RegisterForm,
    NavBar,
  },
};
</script>

<style scoped>

</style>
